.uiWidgetInner {
    background-color: white;
    border-radius: 25px;
    box-shadow: 0 12px 88px 0 rgba(65 88 206 / 33%);
    color: theme("colors.lightHigh");
    height: 100%;
    padding: 24px;
}

.uiWidgetOuter {
    backdrop-filter: blur(25px);
    background: rgb(255 255 255 / 50%);
    border: 1px solid white;
    border-radius: 35px;
    height: 100%;
    padding: 12px;
}
